@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
  font-family: montserrat;
  color: white;
}
body {
  margin: 0;
  background-color: rgb(7, 0, 65);
  padding: 5%;
}
h1 {
  font-size: 1.5em;
}
.navbar {
  padding-bottom: 5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.459);
}
.links a {
  font-size: 1.1em;
  padding-right: 5%;
}

.content {
  padding-top: 5%;
}

.blog-preview {
  padding: 1%;
  margin: 2%;
  border-bottom: 1px solid #fafafa48;
  border-radius: 5px;
}
.blog-preview a {
  text-decoration: none;
}
.blog-preview:hover {
  box-shadow: 3px 4px 6px rgba(255, 255, 255, 0.61);
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 1%;
  background-color: rgb(7, 0, 65);
}
.footer a {
  text-decoration: none;
  font-weight: 100;
}

/* blog details */
.blog-body {
  font-size: 1.1em;
  margin-bottom: 7%;
}
.blog-details button {
  background-color: rgb(7, 0, 65);
  border: 2px solid rgb(255, 0, 0);
  color: red;
  border-radius: 5px;
  padding: 2%;
  font-size: 0.8em;
}
.blog-details button:hover {
  border: 2px solid rgb(255, 0, 0);
  color: red;
}
/* create blog */
form {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 1.2em;
}
form input,
textarea,
select,
button {
  color: black;
  margin-bottom: 5%;
  border-radius: 5px;
  font-size: 1em;
}
form select {
  width: 65%;
  color: black;
}
form select option {
  color: black;
  font-size: 1em;
}
.textarea {
  min-height: 175px;
}
form button {
  width: 40%;
  background-color: rgb(7, 0, 65);
  border: 2px solid rgb(255, 255, 255);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.8em;
  }
  .links a {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.6em;
  }
  /* blog details */
  .blog-details p {
    font-size: 1.2em;
  }
  .blog-body {
    font-size: 1.5em;
  }
  .blog-details button {
    font-size: 1.3em;
  }
  /* create blog */
  form label {
    font-size: 1.2em;
  }
  form select {
    width: 35%;
  }
  form input,
  textarea,
  select {
    font-size: 1.3em;
  }
  form button {
    width: 20%;
    font-size: 1.1em;
  }
}
@media (min-width: 1024px) {
  body {
    padding: 5% 25% 2% 25%;
  }
  h1 {
    font-size: 1.5em;
  }
  .navbar {
    padding-bottom: 3%;
  }
  h2 {
    font-size: 1.3em;
  }
  .links a {
    font-size: 1.2em;
  }
  .links a:hover {
    color: red;
  }
  .content {
    padding-top: 3%;
  }
  /* blog details */
  .blog-details p {
    font-size: 1em;
  }
  .blog-body {
    font-size: 1.1em;
  }
  /* create blog */
  form label {
    font-size: 1em;
  }
  form input,
  textarea,
  select {
    font-size: 1em;
  }
  form button {
    width: 20%;
    font-size: 1em;
  }
  form select option {
    color: black;
    font-size: 1em;
  }
  form select {
    width: 25%;
    color: black;
  }
  form button:hover {
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(7, 0, 65);
    color: rgb(7, 0, 65);
  }

  .blog-details button {
    background-color: rgb(7, 0, 65);
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 1%;
    font-size: 1em;
    cursor: pointer;
  }
  .blog-details button:hover {
    border: 2px solid rgb(255, 0, 0);
    color: red;
  }
}
